/**
 * 主页面
 * luxinwen
 * 2023-01
 */
<style scoped lang="less">
  @import "./main.less";
</style>

<template>
  <Layout class="layout" :class="layoutClass" v-show="!!routeName">
    <div class="layout-logo">
      <div class="layout-logo-content"><img src="~@/assets/images/logo.jpeg" /></div>
      <div class="layout-logo-title">{{ appName }}</div>
    </div>
    <div class="layout-menutree" v-if="menuType === 1">
      <app-menu ref="menulist" :menu-data="menuData" :menu-status="menuStatus"></app-menu>
    </div>
    <div class="layout-menu" v-if="menuType === 2">
      <div class="layout-menu-list">
        <p class="layout-menu-list-item" v-for="(item, index) in menuList" :key="'menu' + index" :class="{'active': checkActiveClass(item.name, 0)}" @click="changeMenu(item)"><Icon :type="item.icon" /> {{ item.title }}</p>
      </div>
    </div>
    <div class="layout-submenu" v-if="menuType === 2">
      <div class="layout-submenu-head">{{ parentTitle }}</div>
      <div class="layout-submenu-list">
        <p class="layout-submenu-list-item" v-for="(item, index) in subMenuList" :key="'submenu' + index" :class="{'active': checkActiveClass(item.name, 1)}" @click="changeSubMenu(item)">{{ item.title }}</p>
      </div>
    </div>
    <div class="layout-main">
      <div class="layout-main-head">
        <div class="layout-main-head-breadcrumb">
          <Icon type="ios-home" size="18" />
          <span v-for="(item, index) in breadcrumb" :key="'breadcrumb' + index" class="layout-main-head-breadcrumb-item" :class="{'current': index === breadcrumb.length - 1}">{{ item }}<i>/</i></span>
        </div>
        <div class="layout-main-head-right">
          <Dropdown>
            <p class="layout-main-head-right-item link">
              <Icon type="ios-contact" size="18" />
              {{ userInfo.username }}
            </p>
            <DropdownMenu slot="list">
              <!-- <DropdownItem disabled>个人信息</DropdownItem> -->
              <DropdownItem @click.native="changePwd">修改密码</DropdownItem>
              <DropdownItem @click.native="goLogout" divided><Icon type="ios-log-out" size="16" /> 退出</DropdownItem>
            </DropdownMenu>
          </Dropdown>
          <!-- <p class="layout-main-head-right-item" @click="goLogout">退出</p> -->
        </div>
      </div>
      <div class="layout-main-body" :style="mainStyle">
        <router-view></router-view>
      </div>
    </div>
  </Layout>
</template>

<script>
  import router from '@/router/router.js';
  import appMenu from './menu.vue';

  export default {
    components: {
      appMenu
    },
    data() {
      let userInfo = this.getUserInfo();
      return {
        appName: process.env.VUE_APP_NAME,
        userInfo, // 用户信息
        menuType: 1,  // 导航样式，1 树状 2 分列
        menuData: [], // 导航数据
        menuHash: {}, // 导航关系
        currentName: '',  // 当前导航
        bodyHeight: 300,  // 内容域高度
        mainStyle: {} // 内容域样式
      };
    },
    computed: {
      routeName() {
        return this.$route.name;
      },
      routeCode() {
        return this.$route.meta ? this.$route.meta.code : '';
      },
      currentLevel() {
        let val = [];
        if (this.currentName) {
          val = this.menuHash[this.currentName].level || [];
        }
        return val;
      },
      breadcrumb() {
        let val = [];
        if (this.currentName) {
          val = this.menuHash[this.currentName].breadcrumb || [];
        }
        return val;
      },
      menuList() {
        return this.menuData.filter(item => item.show);
      },
      subMenuList() {
        let val = [];
        if (this.currentLevel.length > 0) {
          val = (this.menuData[this.currentLevel[0]].children || []).filter(item => item.show);
        }
        return val;
      },
      layoutClass() {
        let val = [];
        if (this.menuType === 1) {
          val.push('layout-has-menutree');
        } else if (this.subMenuList.length < 1) {
          val.push('layout-not-submenu');
        }
        return val;
      },
      menuStatus() {
        let currentLevel = this.currentLevel;
        let openNames = currentLevel.slice(0, -1).map(item => String(item));
        return {
          activeName: this.currentLevel.join('-'),
          openNames
        };
      },
      parentTitle() {
        let val = '';
        if (this.currentLevel.length > 0) {
          let menu = this.menuData[this.currentLevel[0]];
          val = menu.subTitle || menu.title;
        }
        return val;
      }
    },
    created() {
      this.menuData = this.getMenuData(router);
      this.getMenuHash(this.menuData);
    },
    mounted() {
      let docEl = document.documentElement;
      this.bodyHeight = docEl.clientHeight || docEl.getBoundingClientRect().height;
      this.getMainHeight();
    },
    methods: {
      /**
       * 获取导航数据
       */
      getMenuData(list = []) {
        let val = [];
        list.forEach(item => {
          if (item.self) {
            item.children && item.children.forEach(self => {
              if (self.meta && this.checkGrant(self.meta.code)) {
                let menu = {
                  title: self.meta.title,
                  subTitle: self.meta.subTitle,
                  icon: self.meta.icon,
                  path: self.path,
                  name: self.name,
                  show: self.meta.show
                };
                if (menu.title) {
                  val.push(menu);
                }
              }
            });
          } else if (item.meta && this.checkGrant(item.meta.code)) {
            let menu = {
              title: item.meta.title,
              subTitle: item.meta.subTitle,
              icon: item.meta.icon,
              path: item.path,
              name: item.name,
              parent: item.meta.parent,
              show: item.meta.show
            };
            if (item.children) {
              menu.children = this.getMenuData(item.children);
            }
            if (menu.title) {
              val.push(menu);
            }
          }
        });
        return val;
      },
      /**
       * 对导航数据做关系缓存
       */
      getMenuHash(list = [], levelSource = [], breadcrumbSource = []) {
        list.forEach((item, index) => {
          let level = [...levelSource, index];
          let breadcrumb = [...breadcrumbSource];
          let title = item.subTitle || item.title;
          if (item.parent && this.menuHash[item.parent]) {
            level = [...this.menuHash[item.parent].level];
            breadcrumb = [...this.menuHash[item.parent].breadcrumb];
          }
          if (title) {
            breadcrumb.push(title);
          }
          if (item.name) {
            this.menuHash[item.name] = {
              title,
              level,
              breadcrumb,
              path: item.path
            };
          }
          if (item.children) {
            this.getMenuHash(item.children, level, breadcrumb);
          }
        });
      },
      /**
       * 更新导航栏状态
       */
      updateMenuStatus(name) {
        this.currentName = name;
        this.getMainHeight();
      },
      /**
       * 切换导航
       */
      changeMenu(item) {
        let name = item.name;
        if (item.children) {
          name = item.children[0].name;
        }
        this.$router.push({
          name
        });
      },
      /**
       * 切换子导航
       */
      changeSubMenu(item) {
        this.$router.push({
          name: item.name
        });
      },
      /**
       * 检测是否当前导航
       */
      checkActiveClass(name, index) {
        let val = false;
        if (this.menuHash[name].level[index] === this.currentLevel[index]) {
          val = true;
        }
        return val;
      },
      /**
       * 获取内容区域高度
       */
      getMainHeight() {
        let mainHeight = 300;
        let marginBottom = 0;
        let elFooter = document.querySelector('.sp-footer');
        if (!elFooter) {
          mainHeight = this.bodyHeight - (60 + 10);  // padding-top、padding-bottom
          marginBottom = 0;
        } else {
          mainHeight = this.bodyHeight - (60 + 10 + 65);  // padding-top、padding-bottom、footer-height
          marginBottom = 65;
        }
        this.mainStyle = {
          minHeight: mainHeight + 'px',
          marginBottom: marginBottom + 'px'
        };
      },
      /**
       * 修改密码
       */
      changePwd() {
        this.$router.push({
          name: 'changePwd'
        });
      },
      /**
       * 退出登录
       */
      goLogout() {
        this.clearUserInfo();
        this.goToLogin();
      }
    },
    watch: {
      /**
       * 监听路由变化
       */
      routeName: {
        immediate: true,
        handler(newVal) {
          console.log('route name update', newVal, this.userInfo);
          if (!newVal || !this.userInfo || !this.userInfo.token) {
            this.$router.replace({
              name: 'login'
            });
          } else if (this.checkGrant(this.routeCode)) {
            this.$nextTick(() => {
              this.updateMenuStatus(newVal);
            });
          } else {
            this.$router.replace({
              name: 'index'
            });
          }
        }
      }
    }
  };
</script>