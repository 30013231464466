/**
 * 导航栏
 * luxinwen
 * 2023-02
 */
<script>
  export default {
    props: {
      menuData: {
        type: Array,
        default() {
          return [];
        }
      },
      menuStatus: {
        type: Object,
        default() {
          return {
            activeName: '',
            openNames: []
          };
        }
      }
    },
    data() {
      return {
        isInit: false
      };
    },
    methods: {
      addMenuItem(h, menu, name) {
        if (!menu.show) return null;
        let text = menu.subTitle || menu.title;
        let inner = text;
        if (menu.icon) {
          inner = [
            h('Icon', {
              props: {
                type: menu.icon
              }
            }),
            h('span', text)
          ];
        }
        let props = {
          name,
          to: menu.path
        };
        if (menu.target) {
          props.target = menu.target;
        }
        return h('MenuItem', { props }, inner);
      },
      addSubMenu(h, menu, name) {
        if (!menu.show) return null;
        let children = [];
        let text = menu.subTitle || menu.title;
        let inner = text;
        menu.children.forEach((item, index) => {
          let node;
          let subName = name + '-' + index;
          if (item.children) {
            node = this.addSubMenu(h, item, subName);
          } else {
            node = this.addMenuItem(h, item, subName);
          }
          children.push(node);
        });
        if (menu.icon) {
          inner = [
            h('Icon', {
              props: {
                type: menu.icon
              }
            }),
            h('span', text)
          ];
        }
        return h('Submenu', {
          props: {
            name
          }
        }, [
          h('template', {
            slot: 'title'
          }, inner),
          ...children
        ]);
      }
    },
    updated() {
      if (this.isInit) return;
      this.$nextTick(() => {
        this.isInit = true;
        this.$refs.menu1.updateOpened();
        this.$refs.menu1.updateActiveName();
      });
    },
    render: function (h) {
      let childrens = [];
      this.menuData.forEach((item, index) => {
        let menu;
        let name = String(index);
        if (item.children) {
          menu = this.addSubMenu(h, item, name);
        } else {
          menu = this.addMenuItem(h, item, name);
        }
        childrens.push(menu);
      });
      return h('Menu', {
        ref: 'menu1',
        props: {
          activeName: this.menuStatus.activeName,
          openNames: this.menuStatus.openNames,
          theme: 'dark',
          width: 'auto',
          accordion: true
        }
      }, childrens);
    }
  };
</script>